.toast.mtz-toast {
    background-color: #504689;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-width: 376px;

    .toast-header,
    .toast-body {
        background-color: #504689;
        color: #ffffff;
        border-radius: 4px;
    }

    .toast-header {
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;
        border-bottom: none;
        padding: 20px 24px 0px;
    }

    .toast-body {
        padding: 10px 24px 21px;
    }
}

.mtz-toast {
    &__title {
        font-weight: 600;
        font-size: 16px;
    }

    &__description {
        font-size: 12px;
        font-weight: normal;
    }

    button {
        background: none;
        border: none;
        box-shadow: none;
        outline: none;
    }

    &__btn-close,
    &__btn-okay {
        font-weight: bold;
        color: #ffffff;
    }

    &__btn-close {
        font-size: 1rem;
    }

    &__btn-okay {
        font-size: 12px;
        margin-top: 24px;
        line-height: 160%;
    }
}
