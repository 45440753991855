@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter/Inter-Light.ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/Inter/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/Inter/Inter-Medium.ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/Inter/Inter-SemiBold.ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/Inter/Inter-Bold.ttf');
}
