*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*:focus {
    outline: none;
}

/* Width Scroll */
::-webkit-scrollbar {
    width: 18px;
    height: 18px;
}

/* Track Scroll */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle Scroll */
::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    background: #dfe1e3;
    border-radius: 11px;
    background-clip: padding-box;

    &:hover {
        border: 6px solid transparent;
        background: #a5a8aa;
        border-radius: 11px;
        background-clip: padding-box;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;
    width: 100%;
    // min-height: 100vh;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Inter';
    font-size: 0.938rem;
    background-color: #e5e5e5;
    color: #3f3c4c;
}
.mtz-container {
    width: 82%;
    margin: 0 auto;
}

.mtz-container-m {
    padding: 0 30px;
    margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
p,
ul {
    margin-bottom: 10px;
}

// ul {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

a {
    text-decoration: none;
}

img {
    // width: 100%;
    height: fit-content;
}

button {
    // border-radius: 4px !important;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pr-15 {
    padding-right: 15px;
}

.pl-15 {
    padding-left: 15px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.w-50 {
    width: 50%;
}

.w-70 {
    width: 70%;
}
.ptb-30 {
    padding: 30px 0;
}
.pb-30 {
    padding-bottom: 30px;
}

.d-col-center {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.d-space-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.d-flex {
    display: flex;
}

.tag-label {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 55px;
    min-height: 30px;
    border-radius: 4px;
    background: #cfe6f9;
}

.mtz {
    position: relative;
    // display: flex;
    overflow-y: auto;
    width: 100vw;
}

.slick-track {
    display: flex;
    gap: 16px;
}

.slick-prev:before,
.slick-next:before {
    font-size: xxx-large !important;
    color: #d7d7d7 !important;
    opacity: 1 !important;
}

.slick-prev {
    //left: -50px !important;
}

.slick-next {
    right: -25px !important;
}

.slick-slide {
    padding: 0px 5px 0px 5px;
}

.slick-track {
    display: flex;
}

.ant-modal-content {
    .ant-modal-body {
        min-height: 50px;
        padding: 15px 0 15px 0;
    }

    .ant-modal-footer {
        button {
            min-width: 100px;
        }
    }

    .modal-overlay {
        display: flex;
        justify-content: center;

        .modal {
            text-align: center;
            background-color: white;
            width: 500px;
            margin-top: 10%;
            border-radius: 20px;
        }

        .check {
            width: 150px;
        }

        h6 {
            font-weight: 500;
            font-size: 28px;
            margin: 20px 0;
        }

        p {
            font-size: 16px;
            margin: 20px 0;
        }

        // button {
        //   background-color: #ac003e;
        //   width: 150px;
        //   height: 40px;
        //   color: white;
        //   font-size: 14px;
        //   border-radius: 16px;
        //   margin-top: 50px;
        // }
    }
}

.icon-danger:hover {
    color: #ff4d4f !important;
}
.dangerHTML {
    white-space: break-spaces;
    overflow: hidden;
    height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.dangerHTMLOneLine {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    * {
        margin: 0;
    }
}
.ant-card {
    border: solid 1px rgba(0, 0, 0, 0.099);
    .ant-card-cover {
        margin: 1px;
    }
}
// .ant-card-body {
//     padding: 15px !important;
// }

.ant-card .ant-card-cover img {
    border-radius: 4px 4px 0 0;
}
.ant-card .ant-card-meta-description {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}
.ant-card .ant-card-meta {
    display: block;
}
.app-main .ant-card-body .ant-card-meta-title {
    width: auto;
}

.menu-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 169px;
    height: 36px;
    border-radius: 4px;
    color: #795ed5;
    img {
        width: 25px !important;
        margin-right: 8px;
    }
}
.ant-dropdown-menu-item:hover * {
    color: black;
}
.ant-dropdown-menu-item * {
    color: #8f8f8f;
}
.ck-editor__editable {
    min-height: 100px;
    max-height: 300px;
}
// .op-0 {
//     opacity: 0;
// }
// .op-1 {
//     opacity: 1;
// }
// .dp-n {
//     display: none;
// }
// .op-05 {
//     opacity: 0.5;
// }
// .tr-03 {
//     transition: 0.3s;
// }
// .tr-05 {
//     transition: 0.5s;
// }
// .tr-1 {
//     transition: 1s;
// }
// .tr-15 {
//     transition: 1.5s;
// }
// .tr-2 {
//     transition: 2s;
// }

// .dl-025 {
//     animation-delay: 0.25s !important;
// }
// .dl-05 {
//     animation-delay: 0.5s !important;
// }

// .dl-075 {
//     animation-delay: 0.75s !important;
// }
// .dl-1 {
//     animation-delay: 1s !important;
// }
// .dl-15 {
//     animation-delay: 1.5s !important;
// }
// .dl-3 {
//     animation-delay: 3s !important;
// }
// .am-up {
//     animation: ease-in-out 1.5s alternate-reverse;
//     animation-fill-mode: forwards;
//     animation-name: am_up;
// }
// .am-up-down {
//     animation: ease-in-out 3s infinite alternate-reverse;
//     animation-fill-mode: forwards;
//     animation-name: am_up_down;
// }
// @keyframes am_up_down {
//     0% {
//         transform: translateY(-50px);
//     }
//     100% {
//         transform: translateY(0);
//     }
// }
// .am-left {
//     animation: ease-in-out 1.5s alternate-reverse;
//     animation-fill-mode: forwards;
//     animation-name: am_left;
// }

// .am-right {
//     animation: ease-in-out 1.5s alternate-reverse;
//     animation-fill-mode: forwards;
//     animation-name: am_right;
// }

// @keyframes am_up {
//     0% {
//         transform: translateY(0);
//         opacity: 1;
//     }

//     100% {
//         transform: translateY(100vw);
//         opacity: 0;
//     }
// }

// @keyframes am_left {
//     0% {
//         transform: translateX(0);
//     }
//     100% {
//         transform: translateX(-100vw);
//     }
// }

// @keyframes am_right {
//     0% {
//         transform: translateX(0);
//     }
//     100% {
//         transform: translateX(100vw);
//     }
// }
.h3-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #474d66;
}
.header-link {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
