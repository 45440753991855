@import './assets/styles/index.scss';
@import '~video-react/styles/scss/video-react';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

.app-main {
    .ant-card-body {
        overflow: hidden;
        .ant-card-meta-title {
            text-overflow: ellipsis;
            width: 200px;
        }
    }
    position: relative;
    flex-grow: 1;
    background-color: #fff;

    .app-main__body {
        overflow-y: auto;
        overflow-x: hidden;
        // min-height: calc(100vh - 60px);
    }

    .mtz-progressbar {
        &.progress {
            height: 0.5rem;
            border-radius: inherit;

            .progress-bar {
                background-color: #2baaa7;
                animation: progressbar 2s infinite;
            }
        }

        &--fixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1030;
        }
    }

    .btn-default {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 8px 16px;
        width: 140px;
        height: 40px;
        background: linear-gradient(0deg, #f1f7ff, #f1f7ff),
            linear-gradient(
                90deg,
                #328efd -25.92%,
                #eb73a0 55.8%,
                #ffbc64 130.99%
            );
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        color: #328efd;
        cursor: pointer;
    }

    .text-large {
        font-size: 16px;
        font-weight: 600;
        background-clip: text;
    }

    .f-sz-16 {
        font-size: 16px !important;
    }

    .text-bold-600 {
        font-weight: 600 !important;
        color: #304659 !important;
    }

    .text-blue {
        color: #8867e9;
        font-weight: 600;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .background-linear-gradient {
        background: linear-gradient(89.97deg, #0144a9 0.01%, #0078cf 99.97%);
    }

    .background-white {
        background: #fff;
    }
}
.ant-float-btn-content {
    padding: 0 !important;
}
.ant-float-btn-icon {
    width: auto !important;
}
.zalo {
    animation: ease-in-out 0.5s infinite alternate;
    animation-name: zalo;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-delay: 0s;
}
@keyframes zalo {
    0% {
        transform: translateX(0, 0);
    }
    100% {
        transform: translateX(0, 10px);
    }
}
