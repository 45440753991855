#loadingWrapper {
    background: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 3000;
    position: fixed;
    overflow: hidden;

    .loading-icon {
        height: 200px;
        width: 200px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url(../../../assets/images/backgrounds/logo-icon.svg)
            no-repeat center center;
        background-color: transparent;
        border-radius: 50%;
    }

    .loading-icon .loading-outer {
        /* border: 5px solid var(--loading-icon-border); */
        border: 5px solid #8f95b2;
        opacity: 0.9;
        border-right-color: transparent;
        border-left-color: transparent;
        width: 170px;
        height: 170px;
        top: calc(50% - 85px);
        left: calc(50% - 85px);
        position: absolute;
        border-radius: 50%;
        box-shadow: 0 0 35px rgba(0, 76, 61, 0.9);
        animation: spin-left 1s linear infinite;
    }

    @keyframes spin-left {
        from {
            transform: rotate(0deg);
            opacity: 0.2;
        }

        50% {
            transform: rotate(-180deg);
            opacity: 1;
        }

        to {
            transform: rotate(-360deg);
            opacity: 0.2;
        }
    }

    .loading-icon .loading-inner {
        /* border: 5px solid var(--loading-icon-border); */
        border: 5px solid #8f95b2;
        opacity: 0.9;
        border-left-color: transparent;
        border-right-color: transparent;
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(0, 76, 61, 0.9);
        width: 120px;
        height: 120px;
        top: calc(50% - 60px);
        left: calc(50% - 60px);
        position: absolute;
        animation: spin-right 1s linear infinite;
    }

    @keyframes spin-right {
        from {
            transform: rotate(0deg);
            opacity: 0.2;
        }

        50% {
            transform: rotate(180deg);
            opacity: 1;
        }

        to {
            transform: rotate(360deg);
            opacity: 0.2;
        }
    }
}

.hide-loading {
    display: none;
}
